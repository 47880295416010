import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";

/** @jsx jsx */
import { jsx, useThemeUI, Styled } from "theme-ui";
import serializer from "../gatsby-plugin-theme-ui/article-serializer";
import Layout from "../components/Layout";
import Container from "../components/Container";
import ContactForm from "../components/ContactForm";
import ServiceList from "../components/ServiceList";
import SEO from "../components/seo";
import serializers from "../gatsby-plugin-theme-ui/article-serializer";

const ServicePage = ({ data: { sanityInfoContent } }) => {
  const article = sanityInfoContent._rawBody;
  const headline = sanityInfoContent.headline;
  const description = sanityInfoContent.description;

  const renderArticle = () => {
    return <BlockContent blocks={article} serializers={serializers} />;
  };

  const ArticleGrid = () => {
    return (
      <>
        <SEO title={`${headline}`} description={description} />
        <div
          sx={{
            display: "grid",
            gridGap: 4,
            gridTemplateColumns: ["100%", "100%", "65% 35%"],
            py: [0, 0, 4],
          }}
        >
          <article>
            <h1>{headline}</h1>
            {renderArticle()}
          </article>
          <aside
            sx={{
              backgroundColor: "primaryShade",
              minHeight: "100%",
              py: 5,
              px: 4,
            }}
          >
            <ContactForm style={{ py: 2 }} />
            <ServiceList style={{ mt: 5 }} />
          </aside>
        </div>
      </>
    );
  };

  return (
    <Layout>
      <Container>
        <ArticleGrid />
      </Container>
    </Layout>
  );

  // return (
  //   <Layout>
  //     <Container>
  //       <h1>{name}</h1>
  //       <ArticleGrid />
  //     </Container>
  //   </Layout>
  // );
};

ServicePage.propTypes = {};

export default ServicePage;

export const pageQuery = graphql`
  query InfoContentByID($id: String!) {
    sanityInfoContent(id: { eq: $id }) {
      id
      _rawBody
      description
      headline
      seoTitle
      name
    }
  }
`;
